<template>
  <div>
    <v-list-item
      :data-cy="'bulk-action-'+action.code"
      v-for="action in actions"
      :key="action.name"
      link
      @click.stop="$emit('click', action)"
    >
      <v-list-item-action v-if="showIcons">
        <v-icon>{{action.icon}}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <span>{{action.name}}</span>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  computed: {
    showIcons () {
      return this.actions.some(a => a.icon)
    }
  },
  props: {
    actions: Array
  }
}

</script>
